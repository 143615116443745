import React from 'react';
import { useSlider } from '../../components/Slider/Slider';

const Control: React.FC = () => {
  const [state, dispatch] = useSlider();

  return (
    <ul className="none flex-shrink-0 w-65x mrr-20x lg:block">
      {state.slides.map((slide, idx) => (
        <li
          key={idx}
          className="mrb-20x brr-4x brs-s brw-2x"
          style={{
            borderColor: state.activeSlide === idx ? '#ff8749' : 'transparent',
            backgroundColor:
              state.activeSlide === idx ? '#ff8749' : 'transparent',
          }}
        >
          <a
            href="#"
            aria-label={`slide-${idx + 1}`}
            className="block w-full brr-2x overflow-hidden"
            onClick={(event) => {
              event.preventDefault();
              dispatch({ type: 'PAGE', index: idx });
            }}
          >
            {slide}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Control;
