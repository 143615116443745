import React from 'react';
import cx from 'classnames';
import * as s from './shape-title.module.css';

type Props = React.HTMLAttributes<HTMLHeadingElement> & {
  name: string;
  component?: React.ElementType;
};

const capitalize = (x: string) => x.charAt(0).toUpperCase() + x.substr(1);

const ShapeTitle: React.FC<Props> = ({
  name,
  component: Component = 'h3',
  className,
  children,
  ...props
}) => (
  <Component {...props} className={cx(s.Title, s[capitalize(name)], className)}>
    {children}
  </Component>
);

export default ShapeTitle;
