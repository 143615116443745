import React from 'react';
import { graphql, navigate } from 'gatsby';
import Image from 'gatsby-image';
import cx from 'classnames';
import LinkTo from '../../components/LinkTo';
import Mailto from '../../components/Mailto';
import Slider from '../../components/Slider/Slider';
import Slides from '../../components/Slider/Slides';
import Control from '../../components/Control/Control';
import Section from '../../components/Section/Section';
import Select from '../../components/Select/Select';
import { Product, Variant } from '../../modules/product/product';
import * as price from '../../modules/product/price';
import Thumbnails from '../../routes/product/Thumbnails';
import AddToCart from '../../routes/product/AddToCart';
import PopHeart from '../../images/icons/pop-heart.inline.svg';

export const query = graphql`
  fragment CatalogueProductDetail on Catalogue {
    name
    variants {
      id
      name
      canonical
      description
      price
      shipping
      stock
      details {
        subname
        information {
          title
          value
        }
      }
      images {
        childImageSharp {
          fluid(maxWidth: 530, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
          # Create the thumbnails at this step to be able to re-use them on the
          # onPostBuild hook. This is a dirty hack until the issue is fixed.
          fixed(width: 1024, height: 1024, quality: 90) {
            src
          }
        }
      }
    }
  }
`;

type Props = {
  product: Product;
  variant: Variant;
};

const Detail: React.FC<Props> = ({ product, variant }) => {
  const inStock = variant.stock > 0;
  const name = variant.name
    ? `${product.name} - ${variant.name}`
    : product.name;

  const onChange = (variantId: string) => {
    const next = product.variants.find((_) => _.id === variantId);
    if (next) {
      navigate(`/shop/${next.canonical}/`, {
        replace: true,
      });
    }
  };

  return (
    <Section>
      <div className="flex flex-wrap mrr-auto mrl-auto relative md:flex-nowrap">
        <Slider>
          <div className="w-full flex-shrink mrb-25x md:mw-615x md:mrr-25x md:mrb-0">
            <div className="flex justify-between mrb-10x lg:mrb-0">
              <Thumbnails />
              <div className="w-full mrr-auto mrl-auto brr-2x overflow-hidden">
                <Slides>
                  {variant.images.map((image) => (
                    <Image
                      key={image.childImageSharp.fluid.src}
                      fluid={image.childImageSharp.fluid}
                      draggable={false}
                    />
                  ))}
                </Slides>
              </div>
            </div>
            <Control className="lg:none" />
          </div>
        </Slider>

        <div className="flex flex-column pdr-15x pdl-15x md:pdl-0 md:mw-440x">
          <h2 className="flex ff-lora fsz-lg fw-bold fsy-italic blue mrb-10x">
            <span className="mrr-10x">
              {product.name}
              {variant.name && (
                <React.Fragment> - {variant.name}</React.Fragment>
              )}
            </span>
            <PopHeart className="w-23x h-26x" />
          </h2>
          <p className="fsz-md fw-bold mrb-20x">{variant.details.subname}</p>
          {product.variants.length > 1 && (
            <div>
              <Select
                value={variant.id}
                className="w-full mrb-20x md:w-auto"
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
                  onChange(event.currentTarget.value)
                }
              >
                {product.variants.map((variant) => (
                  <option key={variant.id} value={variant.id}>
                    {variant.name} - {price.format(variant.price)} €
                  </option>
                ))}
              </Select>
            </div>
          )}

          <AddToCart product={product} variant={variant} />

          <ul className="fsz-md mrb-20x">
            {!inStock && (
              <li className="mrb-20x">
                Soyez alerté(e) de la disponibilité par email :{' '}
                <Mailto
                  className="current"
                  address="hello@poupeeodette.fr"
                  subject={`${name}: Prévenez moi quand le produit est de nouveau disponible !`}
                >
                  hello@poupeeodette.fr
                </Mailto>
              </li>
            )}
            {variant.details.information.map(({ title, value }, idx) => (
              <li key={idx} className="mrb-20x">
                {title && (
                  <p className={cx('fw-bold', value.length > 0 && 'mrb-2x')}>
                    {renderInformation(title)}
                  </p>
                )}
                <ul>
                  {reactNodes(value)
                    .concat(renderInformationLinks(reactNodes(value)))
                    .map((node, idx) => (
                      <li key={idx}>{renderInformation(node)}</li>
                    ))}
                </ul>
              </li>
            ))}
            <li>
              <p>
                <LinkTo
                  className="current"
                  address="https://www.instagram.com/p/B4nJwSLKYDy/"
                >
                  Voir notre politique de prix juste
                </LinkTo>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </Section>
  );
};

const renderInformationLinks = (nodes: React.ReactNode[]): React.ReactNode => {
  const links = [];

  if (nodes.some((node) => includes(node, 'GOTS'))) {
    links.push(
      <LinkTo className="current" address="https://www.global-standard.org">
        GOTS
      </LinkTo>
    );
  }

  if (nodes.some((node) => includes(node, 'OEKO-TEX'))) {
    links.push(
      <LinkTo className="current" address="https://www.oeko-tex.com">
        OEKO-TEX®
      </LinkTo>
    );
  }

  if (links.length > 0) {
    return (
      <p className="fsz-sm">
        *En savoir plus sur{' '}
        {links.map((link, idx) => (
          <React.Fragment key={idx}>
            {idx < links.length - 1 ? (
              <React.Fragment>{link}, </React.Fragment>
            ) : (
              link
            )}
          </React.Fragment>
        ))}
      </p>
    );
  }

  return null;
};

const renderInformation = (node: React.ReactNode): React.ReactNode => {
  if (includes(node, '$$FIXME_BRODERIE_LINK$$')) {
    return (
      <React.Fragment>
        Broderie prénom 🌈 en supplément à sélectionner{' '}
        <LinkTo address="/shop/broderie-luna-ou-rosa/" className="current">
          ici
        </LinkTo>
      </React.Fragment>
    );
  }

  if (includes(node, '$$FIXME_BRODERIE_EMAIL$$')) {
    return (
      <React.Fragment>
        Après avoir validé votre panier, écrivez-nous un petit mail avec le
        prénom choisi 💌{' '}
        <Mailto
          className="current"
          address="hello@poupeeodette.fr"
          subject="Ma broderie pour Luna ou Rosa"
        >
          hello@poupeeodette.fr
        </Mailto>
      </React.Fragment>
    );
  }

  return node;
};

const includes = (node: React.ReactNode, pattern: string): boolean =>
  typeof node === 'string' && node.includes(pattern);

const reactNodes = (value: string[]): React.ReactNode[] => value;

export default Detail;
