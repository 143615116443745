import React from 'react';
import cx from 'classnames';
import * as s from './select.module.css';

type Props = React.SelectHTMLAttributes<HTMLSelectElement>;

const Select: React.FC<Props> = ({ value, className, children, ...props }) => (
  <div className={cx(s.Container, className)}>
    <select
      {...props}
      value={value}
      className={cx(s.Select, value && s.Selected)}
    >
      {children}
    </select>
  </div>
);

export default Select;
