import Cart from '../../modules/cart/Cart';
import { CartProduct } from '../../modules/cart/definition';
import track from '../../modules/analytics/track';
import useUpdate from '../../components/useUpdate';
import useForceUpdate from '../../components/useForceUpdate';
import usePreviousValue from '../../components/usePreviousValue';

const useAddToCart = (): [boolean, (product: CartProduct) => void] => {
  const [cart, dispatch] = Cart.useCart();
  const previousCart = usePreviousValue(cart);
  const forceUpdate = useForceUpdate();
  const confirmation = previousCart !== cart;

  useUpdate(() => {
    const timeout = setTimeout(forceUpdate, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [confirmation]);

  return [
    confirmation,
    (product) => {
      dispatch({
        type: 'ADD',
        quantity: 1,
        product,
      });

      track({
        category: 'cart',
        action: 'cart_add',
        label: product.variant.id,
      });
    },
  ];
};

export default useAddToCart;
