// extracted by mini-css-extract-plugin
export var Title = "shape-title-module--Title--3LTY-";
export var IndexFabrication = "shape-title-module--IndexFabrication--13WPZ";
export var IndexStitch = "shape-title-module--IndexStitch--sKtLi";
export var IndexMaterials = "shape-title-module--IndexMaterials--2-Q-g";
export var IndexEngagment = "shape-title-module--IndexEngagment--3M7V9";
export var IndexAmbition = "shape-title-module--IndexAmbition--12W3H";
export var ProductAdele = "shape-title-module--ProductAdele--3Y4x2";
export var ProductRosa = "shape-title-module--ProductRosa--3cSTr";
export var ProductAstroBelier = "shape-title-module--ProductAstroBelier--k1hT5";
export var ProductExpression = "shape-title-module--ProductExpression--izM2h";
export var ProductPower = "shape-title-module--ProductPower--1v-Ry";
export var ShopCloset = "shape-title-module--ShopCloset--13XBw";
export var ShopMaterials = "shape-title-module--ShopMaterials--2rRNv";
export var ShopStitch = "shape-title-module--ShopStitch--lkgOs";
export var CartDelay = "shape-title-module--CartDelay--xz9i1";
export var CartEmail = "shape-title-module--CartEmail--2pKDE";
export var CartUrgent = "shape-title-module--CartUrgent--z-gIc";