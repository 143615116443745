import React from 'react';
import cx from 'classnames';
import Button from '../../components/Button/Button';
import { Product, Variant } from '../../modules/product/product';
import * as price from '../../modules/product/price';
import useAddToCart from './useAddToCart';

type Props = {
  product: Product;
  variant: Variant;
};

const AddToCart: React.FC<Props> = ({ variant, product }) => {
  const [confirmation, addToCart] = useAddToCart();
  const inStock = variant.stock > 0;

  const onClick = () => {
    addToCart({
      name: product.name,
      variant: {
        id: variant.id,
        name: variant.name,
        images: variant.images,
        price: variant.price,
        shipping: variant.shipping,
      },
    });
  };

  return (
    <React.Fragment>
      <div className="flex mrb-20x items-center">
        <span className="mrr-15x fw-bold fsz-md ws-nowrap">
          {price.format(variant.price)} €
        </span>
        <Button
          className={cx('mrr-15x ws-nowrap', confirmation && 'btn-success')}
          onClick={onClick}
          disabled={!inStock}
        >
          {inStock ? (
            <React.Fragment>
              {!confirmation ? 'Ajouter' : 'Ajoutée'} au panier
            </React.Fragment>
          ) : (
            'Mince ! Épuisé'
          )}
        </Button>
        {Boolean(variant.shipping) && (
          <span className="fw-light fsz-75">
            Livraison en colis suivi {price.format(variant.shipping)} € en
            France métropolitaine
          </span>
        )}
      </div>
    </React.Fragment>
  );
};

export default AddToCart;
